@import '../../App.scss';

.allC{
    @include flex(center, center, row);	
    padding-top: 1em;
	width: 100%;

    .row{
        display: flex;
        @include media('<=tablet') {
			display: inline;
            min-width: 250px;
		}

        iframe{
            @include media('<=tablet') {
                width: 320px;
            }
        }

        .cityBorder{
            @include media('<=tablet') {
                margin-top: 1em;
                width: 320px;
            }
            border: 1px solid rgb(211,211,211);
            border-radius: 4px;
            padding: 0% 0%;
            margin-right: 0.2em;

            .title{
                background-color: #FAB931;
                padding: 0.6em;
                margin-bottom: 1em;
                text-align: center;
                color: white;
            }

            button{
                background-color: #F2E205; 
                border: none;
                text-align: center;
                display: inline-block;
                cursor: pointer;
                border-radius: 4px;
                margin: 0.2em;
                &:hover {
                    color: white;
                }
            }
            .buttonBogota, .buttonPalmira{
                padding: 0.6em 4.5em;
            }
            .buttonCali{
                padding: 0.6em 5.55em;
            }

            ul{
                list-style: none;
            }
        }
    }
}