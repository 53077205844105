@import '../../App.scss';

footer {
	@include flex(center, center, row);
	color: whitesmoke;
	background: url('../../images/Footer/2Footer.jpg');
	//padding-top: 1em;
	width: 100%;
	height: 200px;

	@include media('<=tablet') {
		height: 400px;
	}
	.row {
		display: flex;
		@include media('<=tablet') {
			display: inline;
		}
		.logo {
			padding-right: 2em;
			@include media('<=tablet') {
				padding-top: 2em;
				padding-left: 1em;
			}
			img {
				// position: relative;
				width: 200px;
			}
		}
		.texto {
			display: flex;
			@include media('<=tablet') {
				padding-top: 1em;
			}
			.menu {
				padding-left: 3em;
				ul {
					list-style: none;
					font-size: 15px;
					a {
						text-decoration: none;
						color: white;
		
						&:hover {
							color: $yellow;
						}
		
						&.active {
							color: $yellow;
						}
					}
				}
			}

			.follow {
				@include media('<=tablet') {
					padding-left: 3em;
				}
				padding-left: 12em;

				ul {
					list-style: none;
					font-size: 12px;
				}
				img {
					cursor: pointer;
					&:hover {
						border-radius: 50%;
						background-color: rgba(230, 233, 51, 0.5);
					}
					width: 50px;
				}
			}
		}
	}
}
