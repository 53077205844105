@import '../../App.scss';

main {
	width: 100%;
	min-height: 100vh;
	background-color: white;
}

.contact__section {
	@include flex(flex-start, center, column);
	margin-top: 50vh;
	
	
	@include media('<=tablet'){
		padding-top: 80px;
		margin-top: 0;
	}

	h3 {
		font-family: 'autography';
		font-size: 5em;
		font-weight: 100;
		text-align: center;
		margin-bottom: 50px;
	}
	h4 {
		font-weight: 400;
		font-style: italic;
	}
}
