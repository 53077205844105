@import '../../App.scss';

.wppButton {
	@include flex(center, center, row);
	position: fixed;
	bottom: 10px;
	right: 10px;
	color: white;
	z-index: 50;
	font-size: 2.5rem;
	padding: 10px;
	border-radius: 15px;
	cursor: pointer;
	transition: all 0.2s ease;
	height: 150px;
	@include media('<=tablet') {
		height: 100px;
	}

	img{
		height: 150px;
		@include media('<=tablet') {
			height: 100px;
		}
	}

	&:hover {
		transform: scale(1.1);
		color: darken($color: white, $amount: 5%);
		background-color: darken($color: transparent, $amount: 5%);
		// background-color: darker(green, 0.1);
	}
}
