@import '../../App.scss';

.group {
	@include flex(center, center, column);
	width: 80%;
	margin-top: 60px;
}

.group__title {
	@include flex(center, center, row);
	background-color: $yellow;
	width: 100%;
	height: 60px;
	font-size: 2em;
	font-weight: bold;
	font-style: italic;
	border-radius: 15px;
	box-shadow: 2px 2px 2px -2px black;
	cursor: pointer;

	&:hover {
		background-color: darken($yellow, 5%);
	}
}

.group__section {
	@include flex(center, center, row);
	flex-wrap: wrap;
	padding-top: 30px;
	max-height: 0px;
	overflow: hidden;
	transition: all 1s;

	&.active {
		max-height: 2300px;
	}
}

.group__circle {
	height: 300px;
	width: 300px;
	background-color: red;
	border-radius: 100%;
	margin: 10px 10px;
	position: relative;

	@include media('<=tablet') {
		width: 250px;
		height: 250px;
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: 100%;
		object-fit: cover;
	}

	&:hover .details {
		opacity: 1;
	}

	.details {
		@include flex(center, center, column);
		opacity: 0;
		position: absolute;
		background-color: $transparentBlack;
		width: 100%;
		height: 100%;
		z-index: 5;
		top: 0;
		left: 0;
		border-radius: 100%;
		color: white;
		font-family: 'autography';
		font-size: 2.5em;
		line-height: 60px;
		font-weight: 100;
		text-align: center;
		transition: all 0.2s ease;
	}
}
