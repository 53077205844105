@import '../../App.scss';

.containerP{
    width: 100%;
    margin-block: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    
        .pedidosButton{        
            background-color: $yellow;
            border-radius: 5px;
            padding: 1em 1.5em;
            font-size: 1.5em;
            font-style: italic;
            font-weight: 100;
            color: #000;
            text-decoration: none;
            transition: transform 0.5s ease;
            &:hover{
                cursor: pointer;
                transform: scale(1.2);
            }
        
        }
}