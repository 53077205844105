@import '../../App.scss';

.carousel__container {
	opacity: 1;
	width: 100%;
	height: 100%;
	transition: opacity 0.5s ease;

	@include media('<=tablet'){
		margin-top: 60px;
	}

	&.fade {
		opacity: 0;
	}
}

.carousel__slide {
	width: 100%;
	// height: 50vh;
	// object-fit: cover;
	object-position: top;
	// object-position: top center;
}

.carousel__pagination {
	width: 20px;
	height: 20px;
	background-color: red;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	opacity: 1;
	background-color: white;
}

.swiper-pagination-bullet-active {
	background: $yellow;
}
