@import '../../App.scss';

.gallery__container {
	// background-color: red;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-auto-rows: minmax(300px, auto);
	grid-auto-flow: dense;

	@include media('>=desktop') {
		grid-template-columns: repeat(auto-fit, minmax(22vw, 1fr));
	}

	@include media('>=tablet') {
		.wide {
			grid-column: span 2;
		}

		.tall {
			grid-row: span 2;
		}
	}

	@include media('<=tablet') {
		.wide {
			grid-column: 1 / -1;
		}

		.tall {
			grid-column: 1 / -1;
		}
	}

	.gallery__item {
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			z-index: 1;
		}

		.details {
			@include flex(center, center, column);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 20px;
			color: white;
			text-align: left;
			background-color: $transparentBlack;
			z-index: 2;
			opacity: 0;
			transition: all 0.2s ease;

			img {
				position: absolute;
				bottom: 20px;
				right: 20px;
				width: 2em;
				height: auto;
			}
		}

		&:hover .details {
			top: 8px;
			left: 8px;
			right: 8px;
			bottom: 8px;
			opacity: 1;
		}
	}
}
