@import '../../App.scss';

header {
	@include flex(center, center, row);
	height: 60px;
	width: 100%;
	position: absolute;
	top: 23vh;
	z-index: 10;
	color: white;
	background-color: transparent;
	transition: background 0.2s ease;

	@include media ('<=tablet'){
		top: 0;
		position: fixed;
		background: black;
		border-top: 2px solid $yellow;
		border-bottom: 2px solid $yellow;
	}


	&.active {
		top: 0;
		position: fixed;
		background: black;
		border-top: 2px solid $yellow;
		border-bottom: 2px solid $yellow;

		nav {
			border: none;
		}
	}

	nav {
		&.shadow{
			background-color: $transparentBlack;
			border-radius: 15px;
		}
		position: relative;
		height: 100%;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
		padding: 0 20px;

		.mobile__nav {
			display: none;
			height: 100%;
			width: 80vw;
			font-size: 2em;

			img {
				width: 80px;
				height: 80px;
			}
		}

		@include media('<=tablet') {

			border: none;

			ul {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				margin-top: 62px;
				background-color: black;
				overflow: hidden;
				height: 0;
				width: 100%;
				transition: all 0.2s ease;
				// border: 2px white solid;
				// border-top: none;

				&.active {
					height: 250px;
				}

				li {
					padding: 10px 0;

					span {
						display: none;
					}
				}
			}

			.mobile__nav {
				@include flex(space-between, center, row);
			}
		}
	}

	ul {
		@include flex(center, center, row);
		height: 100%;
		list-style: none;

		@include media('<=tablet') {
			@include flex(center, center, column);
			li {
				margin: 5px 0;
			}
		}

		@include media('<=phone') {
			// @include flex(center, center, column);
		}

		li {
			@include flex(center, center, row);
			height: 100%;
			cursor: pointer;
			transition: all 0.2s ease;

			span {
				margin: 0 5px;
				font-weight: bold;
			}

			a {
				@include flex(center, center, row);
				width: 100%;
				height: 100%;
				padding: 0 10px;
				text-decoration: none;
				color: white;

				&:hover {
					color: $yellow;
				}

				&.active {
					color: $yellow;
				}
			}
		}
	}
}
